import React from "react";
import Image from "next/image";
import SlideAfterMotion from "@/components/common/animation/SlideAfterMotion";


const AboutSection = ({ topItemData, aboutItemData }) => {

  const getFirst30Words = (str) => {
    return str.split(" ").slice(0, 30).join(" ");
  };

  return (
    <>
      <div className="about_section">
        <div className="about_top">
          <div className="container">
            <div className="about_card_main_wrapper">
              <div className="about_card_wrapper">
                <div className="about_card_image_wrapper">
                  { topItemData && <Image
                    src={topItemData?.image}
                    alt="Picturer"
                    layout="fill"
                  />}
                  
                </div>
                <div className="about_item_wrapper">
                  {topItemData &&
                    topItemData?.items?.map((item, index) => (
                      <div className="about_item" key={index}>
                        <SlideAfterMotion>
                          <h5 className="item_title">
                            {item.title}
                            <b className="color_title"> {item.color_title}</b>
                          </h5>
                        </SlideAfterMotion>

                        <SlideAfterMotion>
                          <p className="item_dec">{item.description}</p>
                        </SlideAfterMotion>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="about_sectio_info_wrapper">
          {aboutItemData &&
            aboutItemData?.map((item) => (
              <div key={item.id} className="about_info_card_wrapper">
                <div className="about_image_wrapper">
                  <Image
                    src={item?.image}
                    alt="Picture of the author"
                    layout="fill"
                  />
                </div>

                <div className="about_info">
                  <SlideAfterMotion>
                    <h2>
                      {item?.title}{" "}
                      <b className="color_title">{item?.color_title}</b>
                    </h2>
                  </SlideAfterMotion>

                  <SlideAfterMotion>
                    <p>
                      {item?.description?.length > 30
                        ? getFirst30Words(item?.description)
                        : item?.description}
                    </p>
                  </SlideAfterMotion>
                  <SlideAfterMotion>
                    <button className="landing_primary_button mid_button">
                      read more
                    </button>
                  </SlideAfterMotion>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default AboutSection;
